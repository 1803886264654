import { isNil } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { reportCompletedEvent } from 'store/reduxStates/eventSlice'
import { showMessage } from 'utils/notification.util'

const websocketUrl = process.env.REACT_APP_WEBSOCKET_URL ?? 'http://localhost:8000'

export const WSMessagesTypes = Object.freeze({
	REPORTS: {
		COMPLETED_NOTIFICATION: 'report_completed_notification',
		FAILED_NOTIFICATION: 'report_failed_notification'
	}
})

const useWebSocket = () => {
	const [socket, setSocket] = useState(null)
	const [messages, setMessages] = useState([])
	const reconnectTimeout = useRef(null)
	const auth = useSelector((state) => state.auth)
	const { user, isAuth } = auth
	const jwt = user?.jwt
	const reconnectInterval = 5000
	const dispatch = useDispatch()

	const handleEvent = message => {
		const data = JSON.parse(message)
		if (isNil(data.type)) {
			console.log('Not a valid websocket event', data)
			return
		}
		const eventsMap = {
			[WSMessagesTypes.REPORTS.COMPLETED_NOTIFICATION]:
				(_response) => {
					const a = document.createElement('a')
					a.href = data?.downloadUrl
					a.click()
					showMessage({ message: 'Report generation is complete. You can download the report now.' })
					dispatch(reportCompletedEvent())
				},
			[WSMessagesTypes.REPORTS.FAILED_NOTIFICATION]:
				(response) => {
					console.log('Report generation failed', response)
					showMessage({ message: 'Report generation failed. Please try again later.', type: 'error' })
				}
		}

		if (isNil(eventsMap[data.type])) {
			console.log(`No handler for event ${data.type}`)
			return
		}
		eventsMap[data.type](data)
	}

	useEffect(() => {
		const connect = () => {
			console.log('Connecting to ws', jwt) // TODO remove after testing
			if (!isNil(jwt)) {
				// Open a WebSocket connection
				console.log({ websocketUrl }) // TODO remove after testing
				const ws = new WebSocket(`${websocketUrl}?jwt=${jwt}&app=admin`)

				// Set the WebSocket object to state
				setSocket(ws)

				ws.onopen = () => {
					console.log('Connection established!') // TODO remove after testing
					clearTimeout(reconnectTimeout.current)
				}

				// Handle incoming messages
				ws.onmessage = event => {
					// TODO remove after testing
					console.log('WebSocket message received:', event.data)
					setMessages(prevMessages => [...prevMessages, event.data])
					handleEvent(event.data)
				}

				// Handle WebSocket errors
				ws.onerror = error => {
					console.error('WebSocket error:', error)
				}

				// Handle WebSocket connection close
				ws.onclose = () => {
					console.error('Closing WebSocket connection')
					reconnectTimeout.current = setTimeout(connect, reconnectInterval)
				}
			}
		}
		connect()

		// Cleanup on unmount
		return () => {
			clearTimeout(reconnectTimeout.current)
			if (socket) {
				socket.close()
			}
		}
	}, [isAuth])

	// Function to send a message to the WebSocket server
	const sendMessage = message => {
		if (socket) {
			socket.send(message)
		}
	}

	return {
		socket,
		messages,
		sendMessage
	}
}

export default useWebSocket
