export const buildFormData = (data, fields = [], files = [], objects = []) => {
	const formData = new FormData()

	fields.forEach((key) => {
		if (data[key] !== undefined) formData.append(key, data[key])
	})
	files.forEach((key) => {
		if (data[key] instanceof File) {
			formData.append(key, data[key], data[key]?.name)
		}
	})
	objects.forEach((key) => {
		formData.append(key, JSON.stringify(data[key]))
	})
	return formData
}

export const getFileDefaultValue = (data, field) => {
	return typeof data[field] === 'string' ? data[field].split('/').pop() : ''
}

export const createSelectOptions = arr =>
	arr.map((el, id) => {
		return { name: el, id: id }
	}, [])


export const  truncateToDecimals = (num, decimals) =>  {
	const factor = Math.pow(10, decimals);
	return Math.floor(num * factor) / factor;
}

export const formatNumberWithCommas = (number, decimals = 2) => {
    if (number === null || number === undefined) return 'N/A';
    
    return number.toLocaleString('en-US', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
    });
} 
