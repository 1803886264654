import _ from "lodash"

export const getDiamondCircleUsers = (params) => ({
	method: 'get',
	url: '/admin/hodl/getUsers',
    params,
	autoexec: true
})

export const getDiamondCircleTypes = () => ({
	method: 'get',
	url: '/admin/hodl/getTypes',
	autoexec: false
})

export const addDiamondCircleUser = (data) => {

	const oneHourInMs = 60 * 1000

	let newStartDate = data?.startDate
	if(!_.isNil(newStartDate)){
		newStartDate = new Date(data.startDate.getTime() - data.startDate.getTimezoneOffset() * oneHourInMs)
	}

	data = {
		...data,
		startDate: newStartDate
	}

	return {
		method: 'post',
		url: '/admin/hodl/addUser',
		data,
		autoexec: false
	}
}

export const restrictDiamondCircleUser = (data) => ({
	method: 'post',
	url: '/admin/hodl/restrict',
	data,
	autoexec: false
})

export const deployHodlVestingContract = (data) => ({
	method: 'post',
	url: '/admin/hodl/deployVestingContract',
	data,
	autoexec: false
})

