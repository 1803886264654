import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	reportCompleted: false
}
export const eventSlice = createSlice({
	name: 'event',
	initialState,
	reducers: {
		reportCompletedEvent: (state) => {
			state.reportCompleted = !state.reportCompleted
		},
	},
})

export const { reportCompletedEvent } = eventSlice.actions

export default eventSlice.reducer
