import { useEffect, useState } from 'react'
import useAxios from 'hooks/useAxios'
import { getVestingWallets } from 'services/VestingWalletsService'

const useVestingWalletsTable = () => {
	const [search, setSearch] = useState('')
	const [limit, setLimit] = useState(5)
	const [offset, setOffset] = useState(0)
	const [vestingWallets, setVestingWallets] = useState({
		rows: [],
		count: 0,
	})
	const [show, setShow] = useState(false);
	const [selectedRow, setSelectedRow] = useState({})

	const handleViewDetails = (row) => {
		setSelectedRow(row)
		setShow(true)
	}

	const afterFetchVestingWallets = (data, error) => {
		if (!error) {
			setVestingWallets(data)
		}
	}

	const [{ loading: loadingVestingWallets }, fetchVestingWallets] = useAxios(
		getVestingWallets({ limit, offset, search }),
		afterFetchVestingWallets
	)

	useEffect(() => {
		fetchVestingWallets()
	}, [limit, offset, search])


	const loading = loadingVestingWallets

	return {
		limit,
		loading,
		data: vestingWallets,
		show,
		selectedRow,
		setOffset,
		setLimit,
		setSearch,
		setShow,
		handleViewDetails
	}
}

export default useVestingWalletsTable
