import _ from 'lodash'
import { Link } from 'react-router-dom'
import { Row, Col, Card, Button } from 'react-bootstrap'
import useDiamondCircleTable from './useDiamondCircleTable'
import PageTitle from 'jsx/layouts/PageTitle'
import { CustomTable } from 'components/common/customTable'
import { SearchField } from 'components/common/searchField'
import { formatDate } from 'utils/date.utils'
import { formatShortAddress } from "utils/common.util";
import upRightArrow from '../../../images/svg/up-right-arrow.svg';

const environment = process.env.REACT_APP_ENVIRONMENT;

const DiamondCircleTable = () => {
	const polygonscanLinkPrefix =
    environment === "production"
      ? "https://polygonscan.com/address/"
      : "https://amoy.polygonscan.com/address/";

	const {
		data,
		limit,
		loading,
		setOffset,
		setLimit,
		setSearch,
		setDeployVestingContractId
	} = useDiamondCircleTable()

	const columns = [
		{
			header: 'Email',
			key: 'email',
		},
		{
			header: 'Start Date',
			render: (row) => formatDate(_.get(row, 'startDate', null), 'timestamp'),
		},
		{
			header: 'End Date',
			render: (row) => formatDate(_.get(row, 'endDate', null), 'timestamp'),
		},
		{
			header: 'HODL Amount',
			key: 'hodlAmount',
		},
		{
			header: 'HODL Bucket',
			render: (row) => row?.bonusMultiplierType.name
		},
		{
			header: 'Restricted',
			render: (row) => Number(row?.isRestricted === 1) ? 'Yes' : 'No'
		},
		{
			header: "Vesting Contract",
			render: (row) =>
			  !_.isNil(row?.vestingContractAddress) ? (
				<a
				  href={`${polygonscanLinkPrefix}${row?.vestingContractAddress}`}
				  target="blank"
				>
				  {formatShortAddress(row?.vestingContractAddress)}{" "}
				  <div><img src={upRightArrow} alt="" /></div>
				</a>
			  ) : (
				<Button
				  className="mr-2"
				  variant="danger"
				  onClick={() => {
					setDeployVestingContractId(row.id);
				  }}
				>
				  Deploy
				</Button>
			  ),
		  },

	]

	return (
		<>
			<PageTitle title={'Diamond Circle Users'} />

			<Row>
				<Col lg={12}>
					<Card>
						<Card.Header>
							<Card.Title>Current Users</Card.Title>

							<Link to="add-diamond-circle-user">
								<Button className="mr-2" variant="success">
									Add User
								</Button>
							</Link>
							<Link to="add-diamond-circle-user">
								<Button className="mr-2" variant="danger">
									Restrict User
								</Button>
							</Link>
							<SearchField {...{ setSearch }} />
						</Card.Header>
						<Card.Body>
							<CustomTable
								{...{
									data,
									loading,
									limit,
									setLimit,
									setOffset,
									columns
								}}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default DiamondCircleTable
