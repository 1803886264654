import React, { useContext } from 'react'

/// React router dom
import { Switch, Route } from 'react-router-dom'

/// Css
import './index.css'
import './chart.css'
import './step.css'

/// Layout
import Nav from './layouts/nav'
import Footer from './layouts/Footer'
import ScrollToTop from './pages/ScrollToTop'
import { ThemeContext } from '../context/ThemeContext'
import { GoScoreTable } from 'components/goScoreBadge/goScoreBadgeTable'
import { GoScoreBadgeForm } from 'components/goScoreBadge/goScoreBadgeForm'
import { AdminLogin } from 'components/adminLogin'
import { ProjectsTable } from 'components/projects/projectsTable'
import { TransactionsTable } from 'components/transactions/transactionsTable'
import { FanPurchaseTransactionsTable } from 'components/fanPurchaseTransactions/fanPurchaseTransactionsTable'
import { UsersTable } from 'components/users/usersTable'
import { ReviewsTable } from 'components/reviews/reviewsTable'
import { NewsreelsTable } from 'components/newsreels/newsreelsTable'
import { UserForm } from 'components/users/userForm'
import { ProjectForm } from 'components/projects/projectForm'
import { EditUserForm } from 'components/users/editUserForm'
import { Dashboard } from 'components/dashboard'
import { CommentsTable } from 'components/comments/commentsTable'
import { GoScoreStageTable } from 'components/goScoreStage/goScoreStageTable'
import { GoScoreStageForm } from 'components/goScoreStage/goScoreStageForm'
import { StudiosTable } from 'components/studios/studiosTable'
import { StudioForm } from 'components/studios/studioForm'
import FanLevelTable from 'components/fanLevel/fanLevelTable/FanLevelTable'
import FanLevelForm from 'components/fanLevel/fanLevelForm/FanLevelForm'
import { GoScoreConfigForm } from 'components/goScoreConfigForm'
import { DaoTierTable } from 'components/daoTier/daoTierTable'
import { DaoTierForm } from 'components/daoTier/daoTierForm'
import { CampaignsTable } from 'components/campaigns/campaignTable'
import { CampaignForm } from 'components/campaigns/campaignForm'
import { JoinUsersTable, ProjectCampaignsTable } from 'components/projectCampaigns'
import { ConfigurationForm } from 'components/configurationForm'
import { Troubleshoot } from 'components/troubleshoot'
import { FanSupport } from 'components/support'
import { DigestTable } from 'components/emails/digestTable'
import { TriggeredTable } from 'components/emails/triggeredTable'
import { DigestForm } from 'components/emails/digestForm'
import { TriggeredForm } from 'components/emails/triggeredForm'
import { CollaboratorRoleTable } from 'components/collaboratorRole/collaboratorRoleTable'
import { CollaboratorRoleForm } from 'components/collaboratorRole/collaboratorRoleForm'
import { TokenSaleCampaignGroupsTable } from 'components/tokenSaleCampaigns/tokenSaleCampaignGroupsTable'
import { TokenSaleCampaignGroupForm } from 'components/tokenSaleCampaigns/tokenSaleCampaignGroupForm'
import { TokenSaleCampaignsTable } from 'components/tokenSaleCampaigns/tokenSaleCampaignsTable'
import { TokenSaleCampaignForm } from 'components/tokenSaleCampaigns/tokenSaleCampaignForm'
import { RewardUnlockConfigForm } from 'components/rewardUnlockConfigForm'
import { RRSTable } from 'components/rrs/RRSTable'
import { DiamondCircleTable } from 'components/diamondCircle/diamondCircleTable'
import { DiamondCircleForm } from 'components/diamondCircle/diamondCircleForm'
import { UsersRestrictedTable } from 'components/usersRestricted/usersTable'
import { IngXRestrictionTable } from 'components/ingXRestriction/ingXRestrictionTable'
import { IngXRestrictionAddForm } from 'components/ingXRestriction/ingXRestrictionAddForm'
import ContestTable from 'components/contests/contestTable/ContestTable'
import { ContestProjectsTable } from 'components/contests/contestProjectsTable'
import { ContestForm } from 'components/contests/contestForm'
import Reports from 'components/reports/Reports'
import { RewardUnlock } from 'components/rewardUnlock'
import { FanDashboardCarousel } from 'components/fanDashboardCarousel'
import { PerksProgram } from 'components/perksProgram'
import LockedTRSForm from 'components/lockedTRS/lockedTRSForm/lockedTRSForm'
import { LockedTRSTable } from 'components/lockedTRS/lockedTRSTable'
import { VestingWalletsTable } from 'components/vestingWallets/VestingWalletsTable'
import { VestingWalletsForm } from 'components/vestingWallets/VestingWalletsForm'

const Markup = () => {
	const { menuToggle } = useContext(ThemeContext)

	const routes = [
		/// Filmio
		{ url: 'goscore-badges', component: GoScoreTable },
		{ url: 'new-badge', component: GoScoreBadgeForm },
		{ url: 'edit-badge/:id', component: GoScoreBadgeForm },
		{ url: 'projects', component: ProjectsTable },
		{ url: 'perk_program', component: PerksProgram },
		{ url: 'transactions', component: TransactionsTable },
		{ url: 'fan-purchase-transactions', component: FanPurchaseTransactionsTable },
		{ url: 'edit-project/:id', component: ProjectForm },
		{ url: 'users', component: UsersTable },
		{ url: 'reports', component: Reports },
		{ url: 'users-restricted', component: UsersRestrictedTable },
		{ url: 'new-user', component: UserForm },
		{ url: 'edit-user/:id', component: EditUserForm },
		{ url: 'reviews', component: ReviewsTable },
		{ url: 'newsreels', component: NewsreelsTable },
		{ url: 'comments', component: CommentsTable },
		{ url: 'goscore-stages', component: GoScoreStageTable },
		{ url: 'edit-goscore-stage/:id', component: GoScoreStageForm },
		{ url: 'studios', component: StudiosTable },
		{ url: 'new-studio', component: StudioForm },
		{ url: 'edit-studio/:id', component: StudioForm },
		{ url: 'superfan-level', component: FanLevelTable },
		{ url: 'new-superfan-level', component: FanLevelForm },
		{ url: 'edit-superfan-level/:id', component: FanLevelForm },
		{ url: 'edit-goscore-config', component: GoScoreConfigForm },
		{ url: 'edit-reward-unlock-config', component: RewardUnlockConfigForm },
		{ url: 'fan-dashboard-carousel', component: FanDashboardCarousel },
		{ url: 'dao-tier', component: DaoTierTable },
		{ url: 'new-dao-tier', component: DaoTierForm },
		{ url: 'edit-dao-tier/:id', component: DaoTierForm },
		{ url: 'campaigns', component: CampaignsTable },
		{ url: 'contests', component: ContestTable },
		{ url: 'contest/projects/:contestId', component: ContestProjectsTable },
		{ url: 'new-campaign', component: CampaignForm },
		{ url: 'edit-campaign/:id', component: CampaignForm },
		{ url: 'new-contest', component: ContestForm },
		{ url: 'edit-contest/:id', component: ContestForm },
		{ url: 'campaign/users/:campaignId', component: UsersTable },
		{ url: 'project-campaigns', component: ProjectCampaignsTable },
		{ url: 'troubleshoot', component: Troubleshoot },
		{ url: 'support', component: FanSupport },
		{ url: 'rewardUnlock', component: RewardUnlock },
		{ url: 'project-campaign/users/:projectId', component: JoinUsersTable },
		{ url: 'configuration', component: ConfigurationForm },
		{ url: 'email-digest', component: DigestTable },
		{ url: 'create-email-digest', component: DigestForm },
		{ url: 'edit-digest-email/:id', component: DigestForm },
		{ url: 'email-triggered', component: TriggeredTable },
		{ url: 'create-email-triggered', component: TriggeredForm },
		{ url: 'edit-triggered-email/:id', component: TriggeredForm },
		{ url: 'collaborator-roles', component: CollaboratorRoleTable },
		{ url: 'new-collaborator-role', component: CollaboratorRoleForm },
		{ url: 'edit-collaborator-role/:id', component: CollaboratorRoleForm },
		{ url: 'token-sale-campaign-group', component: TokenSaleCampaignGroupsTable },
		{ url: 'diamond-circle', component: DiamondCircleTable },
		{ url: 'add-diamond-circle-user', component: DiamondCircleForm },
		{ url: 'locked-trs', component: LockedTRSTable },
		{ url: 'add-locked-trs-user', component: LockedTRSForm },
		{ url: 'vesting-wallets', component: VestingWalletsTable },
		{ url: 'add-vesting-wallets', component: VestingWalletsForm },
		{ url: 'ingx-restriction', component: IngXRestrictionTable },
		{ url: 'add-ingx-restriction-user', component: IngXRestrictionAddForm },
		{ url: 'new-token-sale-campaign', component: TokenSaleCampaignGroupForm },
		{ url: 'edit-token-sale-campaign-group/:id', component: TokenSaleCampaignGroupForm },
		{ url: 'edit-token-sale-campaign/:id', component: TokenSaleCampaignForm },
		{ url: 'token-sale-campaigns/:groupId', component: TokenSaleCampaignsTable },
		{ url: 'rrs', component: RRSTable },
		{ url: 'auth/:token', component: AdminLogin },
		{ url: '*', component: Dashboard },
	]
	let path = window.location.pathname
	path = path.split('/')
	path = path[path.length - 1]
	let pagePath = path.split('-').includes('page')

	setTimeout(function () {
		var btn = document.querySelector('#main-wrapper')
		var metisMenu = document.querySelector('.metismenu')
		metisMenu.addEventListener('mouseenter', toggleFunc1)
		metisMenu.addEventListener('mouseleave', toggleFunc2)
		function toggleFunc1() {
			return btn.classList.add('iconhover-toggle')
		}
		function toggleFunc2() {
			return btn.classList.remove('iconhover-toggle')
		}
	}, 200)

	return (
		<>
			<div
				id={`${!pagePath ? 'main-wrapper' : ''}`}
				className={`${!pagePath ? 'show' : 'mh100vh'}  ${menuToggle ? 'menu-toggle' : ''}`}
			>
				{!pagePath && <Nav />}

				<div className={`${!pagePath ? 'content-body' : ''}`}>
					<div
						className={`${!pagePath ? 'container-fluid' : ''}`}
						style={{ minHeight: window.screen.height - 60 }}
					>
						<Switch>
							{routes.map((data, i) => (
								<Route key={i} exact path={`/${data.url}`} component={data.component} />
							))}
						</Switch>
					</div>
				</div>
				{!pagePath && <Footer />}
			</div>
			<ScrollToTop />
		</>
	)
}

export default Markup
