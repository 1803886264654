export const getMarketingUsersCsvReport = (params) => ({
	method: 'post',
	url: '/admin/download/marketingReport',
	params,
	autoexec: false
})

export const getReportsService = () => ({
	method: 'get',
	url: `/admin/reports`,
	autoexec: true
})

export const getUnlockRewardUserService = (params) => ({
	method: 'get',
	url: '/admin/userUnlockedRewards',
	params,
	autoexec: false,
})

export const updateUserUnlockedRewardService = (data) => ({
	method: 'put',
	url: '/admin/userUnlockedReward',
	data
})

export const getPerksProgramProjectsService = (params) => ({
	method: 'get',
	url: '/admin/projects/perksProgram',
	params
})

export const addPerksProgramProjectsService = (data) => ({
	method: 'post',
	url: '/admin/projects/perksProgram',
	data
})

export const deletePerksProgramProjectsService = (data) => ({
	method: 'delete',
	url: '/admin/projects/perksProgram',
	data
})
