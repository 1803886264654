import { useEffect, useState } from 'react'
import useAxios from 'hooks/useAxios'
import { useHistory } from 'react-router-dom'
import { showMessage } from 'utils/notification.util'
import {
    bulkCreateVestingWallets,
    getVestingSubwalletsTypes
} from 'services/VestingWalletsService'
import { getUsers } from 'services/UserService'
import _ from 'lodash'

function validateVestingWalletParams(params) {
	const requiredFields = [
		'email', 'total', 'startDate', 'schedule', 'endDate', 
		'lockup', 'numIntervals', 'initialRelease', 
		'initialVesting', 'vestingPercentage', 
		'releasePercentage', 'vestingSubwalletTypeId'
	];

	for (const wallet of params) {
		for (const field of requiredFields) {
			if (wallet[field] === null) {
				return `Missing required field: ${field}`
			}
		}

		if (typeof wallet.email !== 'string') {
			return 'email must be a string'
		}

		if (wallet.newUserData) {
			if (wallet.newUserData.firstName && typeof wallet.newUserData.firstName !== 'string') {
				return 'firstName must be a string'
			}
			if (wallet.newUserData.lastName && typeof wallet.newUserData.lastName !== 'string') {
				return 'lastName must be a string'
			}
		}
	}

	return '';
}

const useVestingWalletsForm = () => {
	const history = useHistory()
	const [vestingSubwalletTypeId, setvestingSubwalletTypeId] = useState(null)
	const [data, setData] = useState([{
		email: null,
		total: null,
		schedule: 30 * 24 * 60 * 60,
		endDate: null,
		lockup: null,
		numIntervals: null,
		initialRelease: null,
		initialVesting: null,
		vestingPercentage: null,
		releasePercentage: null,
		vestingSubwalletTypeId: null,
		newUserData: {
			firstName: null,
			lastName: null
		},
		userExists: false,
	}])
	const [types, setTypes] = useState([])

	const afterFetchTypes = (response, error) => {
		if (!error) {
			setTypes([...response])
		}
	}
	const [{ loading: loadingVestingSubwalletsTypes }, fetchTypes] = useAxios(getVestingSubwalletsTypes(), afterFetchTypes)

	const [{ loading: checkingUser }, fetchUserCheck] = useAxios(
		getUsers({ search: '', limit: 1 }), 
		null,
		null
	)

	const checkUserExistence = (email, index) => {
		if (!email) return;
		
		fetchUserCheck({ params: { search: email, limit: 1 } })
			.then(response => {
				const userExists = response?.data?.rows?.length > 0
				const userData = userExists ? response.data.rows[0] : null

				const updatedData = [...data]
				updatedData[index] = {
					...updatedData[index],
					newUserData: userExists 
						? { firstName: userData.firstName, lastName: userData.lastName }
						: { firstName: null, lastName: null },
					userExists
				}

				setData(updatedData)
				if(!userExists) {
					showMessage({ message: 'User does not exist. Please insert the first and last name.' })
				}
			})
			.catch(() => {
				const updatedData = [...data]
				updatedData[index] = {
					...updatedData[index],
					userExists: false,
					newUserData: { firstName: null, lastName: null }
				}
				
				setData(updatedData)
			})
	}

	const afterAdd = (_data, error) => {
		if (!error) {
			// Create a downloadable text file with the response data
			const blob = new Blob([JSON.stringify(_data, null, 4)], { type: 'text/plain' });
			const url = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.download = 'vesting_wallets_response.txt';
			link.click();
			URL.revokeObjectURL(url);
			
			const message = `${data.length} vesting wallet(s) added successfully!`
			showMessage({ message })
			history.push('/vesting-wallets')
		} else {
			const blob = new Blob([JSON.stringify(_data, null, 4)], { type: 'text/plain' });
			const url = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.download = 'vesting_wallets_error_object.txt';
			link.click();
			URL.revokeObjectURL(url);
			showMessage({ message: error, type: 'error' })
		}
	}

	const [{ loading: creatingVestingWallets }, createVestingWallets] = useAxios(bulkCreateVestingWallets(data), afterAdd)

	const handleChange = (state, index = 0) => {

		const updatedData = [...data]

		Object.keys(state).forEach(key => {
			updatedData[index][key] = state[key]	
		})

		setData(updatedData)
	}

	const addWallet = () => {
		if (!_.isNil(vestingSubwalletTypeId)) {
			setData([...data, {
				email: null,
				total: null,
				schedule: 30 * 24 * 60 * 60,
				endDate: null,
				lockup: null,
				numIntervals: null,
				initialRelease: null,
				initialVesting: null,
				vestingPercentage: null,
				releasePercentage: null,
				vestingSubwalletTypeId: vestingSubwalletTypeId,
				newUserData: {
					firstName: null,
					lastName: null
				},
				userExists: false,
			}])
		} else {
			showMessage({ 
				message: 'Please select Vesting Subwallet Type first', 
				type: 'error' 
			})
		}
	}

	const removeWallet = (index) => {
		if (data.length > 1) {
			const updatedData = data.filter((_, i) => i !== index)
			setData(updatedData)
		}
	}

	const handleChangeType = (type) => {
		setvestingSubwalletTypeId(type)
		
		const updatedData = data.map(wallet => ({
			...wallet,
			vestingSubwalletTypeId: type
		}))
		setData(updatedData)
	}

	const handleSubmit = () => {
		const invalidNewUsers = data.some(wallet => 
			!wallet.userExists && 
			(!wallet.newUserData.firstName || !wallet.newUserData.lastName)
		)

		if (invalidNewUsers) {
			showMessage({ 
				message: 'For new users, first name and last name are required', 
				type: 'error' 
			})
			return
		}

		const validationMessage = validateVestingWalletParams(data)
		if (validationMessage !== '') {
			showMessage({ message: validationMessage, type: 'error' })
		} else {
			createVestingWallets()
		}
	}

	const handleCancel = () => {
		history.goBack()
	}

	const loading = creatingVestingWallets || loadingVestingSubwalletsTypes

	useEffect(() => {
		fetchTypes()
	}, [])

	return {
		types,
		data,
		loading,
		vestingSubwalletTypeId,
		handleCancel,
		handleChange,
		handleChangeType,
		handleSubmit,
		addWallet,
		checkUserExistence,
		removeWallet
	}
}

export default useVestingWalletsForm
