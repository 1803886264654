import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import upRightArrow from '../../../images/svg/up-right-arrow.svg';

const environment = process.env.REACT_APP_ENVIRONMENT

export function VestingWalletDetailsModal(props) {
    const polygonscanLinkPrefix = environment === "production" ? "https://polygonscan.com/address/" : "https://amoy.polygonscan.com/address/"
    const details = props.row

    return (
        <Modal
            className="fade"
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Vesting Wallet details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row className='modalRow'>
                        <Col xs={6} md={3}> Name: </Col>
                        <Col xs={8} md={7}> {details?.user?.displayName} </Col>
                    </Row>
                    <Row className='modalRow'>
                        <Col xs={6} md={3}> Schedule: </Col>
                        <Col xs={8} md={7}>
                            {Math.floor(details?.schedule / (60 * 60 * 24))} Days
                        </Col>
                    </Row>
                    <Row className='modalRow'>
                        <Col xs={6} md={3}> Release Percentage: </Col>
                        <Col xs={8} md={7}>
                            {details?.releasePercentage}%
                        </Col>
                    </Row>
                    <Row className='modalRow'>
                        <Col xs={6} md={3}> Vesting Percentage: </Col>
                        <Col xs={8} md={7}>
                            {details?.vestingPercentage}%
                        </Col>
                    </Row>
                    <Row className='modalRow'>
                        <Col xs={6} md={3}>
                            Initial Vesting:
                        </Col>
                        <Col xs={8} md={7}> {details?.initialVesting} FAN </Col>
                    </Row>
                    <Row className='modalRow'>
                        <Col xs={6} md={3}>
                            Initial Release:
                        </Col>
                        <Col xs={8} md={7}> {details?.initialRelease / 100}% </Col>
                    </Row>
                    <Row className='modalRow'>
                        <Col xs={6} md={3}> Leakout: </Col>
                        <Col xs={8} md={7}>
                            {details?.numIntervals} Months
                        </Col>
                    </Row>
                    <Row className='modalRow'>
                        <Col xs={6} md={3}> Lockup: </Col>
                        <Col xs={8} md={7}>
                            {details?.lockup} Months
                        </Col>
                    </Row>
                    <Row className='modalRow'>
                        <Col xs={6} md={3}> Vesting Contract: </Col>
                        <Col xs={8} md={7}>
                            <a href={`${polygonscanLinkPrefix}${details?.contractAddress}`} target='blank'>{details?.contractAddress} <img src={upRightArrow} alt="" /></a>
                        </Col>
                    </Row>
                    <Row className='modalRow'>
                        <Col xs={6} md={3}> Custodial Wallet: </Col>
                        <Col xs={8} md={7}>
                            <a href={`${polygonscanLinkPrefix}${details?.walletAddress}`} target='blank'>{details?.walletAddress} <img src={upRightArrow} alt="" /> </a>
                        </Col>
                    </Row>
                </Container>


            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}