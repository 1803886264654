import _ from "lodash";
import { DateTime } from 'luxon'
import { Link } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import useVestingWalletsTable from "./useVestingWalletsTable";
import PageTitle from "jsx/layouts/PageTitle";
import { CustomTable } from "components/common/customTable";
import { SearchField } from "components/common/searchField";
import { formatDate } from "utils/date.utils";
import { VestingWalletDetailsModal } from './VestingWalletDetailsModal'

const VestingWalletsTable = () => {

  const {
    data,
    limit,
    loading,
    show,
    selectedRow,
    setOffset,
    setLimit,
    setSearch,
    setShow,
    handleViewDetails
  } = useVestingWalletsTable();

  const columns = [
    {
      header: "Email",
      key: "user.email",
    },
    {
      header: "Type",
      key: "type.name",
    },
    {
      header: "Total",
      render: (row) => `${row?.total} FAN`,
    },
    {
      header: "Total Released",
      render: (row) => `${row?.totalReleased} FAN`,
    },
    {
      header: "Total Vested",
      render: (row) => `${row?.totalVested} FAN`,
    },
    {
      header: "Start Date",
      render: (row) => formatDate(DateTime.fromSeconds((_.get(row, "startDate", null))), "timestamp"),
    },
    {
      header: "End Date",
      render: (row) => formatDate(DateTime.fromSeconds((_.get(row, "endDate", null))), "timestamp"),
    },
    {
			header: 'Details',
			render: (row) => (
				<div className="d-flex">
					<div onClick={() => { handleViewDetails(row) }} className="btn btn-primary shadow btn-xs sharp mr-1">
						<i className="fa fa-eye"></i>
					</div>
				</div>
			),
		},
  ];

  return (
    <>
      <PageTitle title={"Vesting Wallets"} />

      <VestingWalletDetailsModal show={show}
				onHide={() => setShow(false)} row={selectedRow}/>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Current Wallets</Card.Title>

              <Link to="add-vesting-wallets">
                <Button className="mr-2" variant="success">
                  Add Vesting Wallets
                </Button>
              </Link>
              <SearchField {...{ setSearch }} />
            </Card.Header>
            <Card.Body>
              <CustomTable
                {...{
                  data,
                  loading,
                  limit,
                  setLimit,
                  setOffset,
                  columns,
                }}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default VestingWalletsTable;
