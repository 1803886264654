import { useState } from 'react'
import useAxios from 'hooks/useAxios'
import { showMessage } from 'utils/notification.util'
import { getConfiguration, updateConfiguration } from 'services/ConfigurationService'
import { fillWalletPool } from 'services/WalltPoolService'
import { deploySaleVestingSchedulesService, reconcileSaleTransactionsService } from 'services/HelperService'

const useConfigurationForm = () => {
	const [data, setData] = useState({
		stakeLimit: '',
		creatorShareLimit: '',
		creatorShareReward: '',
		projectShareLimit: '',
		projectShareReward: '',
		enableCreatorShare: false,
		enableProjectShare: false,
		ingXWalletLimit: '',
		ingXGroupLimit: '',
		fanPurchaseProcessingFeesPercentage: '',
		fgrChangeDate: null
	})

	const afterFetchConfig = (config, error) => {
		if (!error) {
			config.fgrChangeDate = config?.fgrChangeDate ? new Date(config?.fgrChangeDate) : null
			setData(config)
		}
	}

	const [{ loading }] = useAxios(getConfiguration(), afterFetchConfig)

	const afterUpdateConfig = (config, error) => {
		if (!error) {
			config.fgrChangeDate = config?.fgrChangeDate ? new Date(config?.fgrChangeDate) : null
			setData(config)
			const message = 'Configuration updated successfully!'
			showMessage({ message })
		}
	}
	const [{ loading: updating }, updateConfig] = useAxios(updateConfiguration(data), afterUpdateConfig)


	const afterUpdateGoScores = (_config, error) => {
		if (!error) {
			const message = 'GoScores updated successfully!'
			showMessage({ message })
		}
	}
	const [{ loading: filling }, fillWallets] = useAxios(fillWalletPool(), afterUpdateGoScores)

	const [{ loading: reconcilingSaleTransactions }, reconcileSaleTransactions] = useAxios(
		reconcileSaleTransactionsService(), (_data, error) => {
			if (!error) {
				const message = 'Sale transactions reconcile completed successfully!'
				showMessage({ message })
			}
		})

	const [{ loading: deployingSaleVestingSchedules }, deploySaleVestingSchedules] = useAxios(
		deploySaleVestingSchedulesService(), (_data, error) => {
			if (!error) {
				const message = 'Sale Vesting deployed successfully!'
				showMessage({ message })
			}
		})

	const handleSaveChanges = () => {
		updateConfig()
	}

	const handleFillWalletPool = () => {
		fillWallets()
	}

	const showMask = loading || updating || filling || reconcilingSaleTransactions || deployingSaleVestingSchedules

	const handleChange = (payload) => {
		if (payload.welcomeRewardAmount && parseInt(payload.welcomeRewardAmount) < 0) {
			showMessage({ message: 'Welcome bonus amount must be greater than 0' })
			return
		}
		setData({
			...data,
			...payload,
		})
	}

	return {
		data,
		showMask,
		handleChange,
		handleSaveChanges,
		handleFillWalletPool,
		deploySaleVestingSchedules,
		reconcileSaleTransactions
	}
}

export default useConfigurationForm
