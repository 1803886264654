import { Suspense } from 'react'

/// Components
import Index from './jsx'
import { withRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
/// Style
import './vendor/swiper/swiper-bundle.css'
import './vendor/bootstrap-select/dist/css/bootstrap-select.min.css'
import 'utils/axios.util'
import './css/style.css'
import 'react-toastify/dist/ReactToastify.css'
import { Redirect, Route, Switch } from 'react-router-dom/cjs/react-router-dom.min'
import { AdminLogin } from 'components/adminLogin'
import { useSelector } from 'react-redux'
import { isAuthenticated } from 'utils/common.util'
import useWebSocket from 'hooks/useWebsockets'

function App() {
	const auth = useSelector((state) => state.auth)
	const { user } = auth
	const isAuth = isAuthenticated(user)
	useWebSocket()
	const routes = (
		<Switch>
			<Route path="/auth/:token" component={AdminLogin} />
			<Route path="*">
				<Redirect to="/auth/no-token" />
			</Route>
		</Switch>
	)

	return isAuth ? (
		<>
			<Suspense
				fallback={
					<div id="preloader">
						<div className="sk-three-bounce">
							<div className="sk-child sk-bounce1"></div>
							<div className="sk-child sk-bounce2"></div>
							<div className="sk-child sk-bounce3"></div>
						</div>
					</div>
				}
			>
				<Index />
			</Suspense>
			<ToastContainer />
		</>
	) : (
		<>
			<Suspense
				fallback={
					<div id="preloader">
						<div className="sk-three-bounce">
							<div className="sk-child sk-bounce1"></div>
							<div className="sk-child sk-bounce2"></div>
							<div className="sk-child sk-bounce3"></div>
						</div>
					</div>
				}
			>
				{routes}
			</Suspense>
			<ToastContainer />
		</>
	)
}

export default withRouter(App)
