import { useEffect, useState } from 'react'
import useAxios from 'hooks/useAxios'
import { getDiamondCircleUsers, deployHodlVestingContract } from 'services/DiamondCircleService'
import { showMessage } from 'utils/notification.util'

const useDiamondCircleTable = () => {
	const [search, setSearch] = useState('')
	const [limit, setLimit] = useState(5)
	const [offset, setOffset] = useState(0)
	const [users, setUsers] = useState({
		rows: [],
		count: 0,
	})
	const [deployVestingContractId, setDeployVestingContractId] = useState(null)

	const afterFetchUsers = (data, error) => {
		if (!error) {
			setUsers(data)
		}
	}

	const afterDeploy = (_data, error) => {
		if (!error) {
			const message = `Vesting Contract Deployed Successfully for user ${_data.email}!`
			showMessage({ message })
			fetchUsers() // Refresh the users list after successful deployment
		} else {
			showMessage({ message: _data.message || 'Deployment failed', type: 'error' })
			setDeployVestingContractId(null)
		}
	}

	const [{ loading: loadingUsers }, fetchUsers] = useAxios(
		getDiamondCircleUsers({ limit, offset, search }),
		afterFetchUsers
	)

	const [{ loading: deploying }, deployVestingContract] = useAxios(
        deployHodlVestingContract({ id: deployVestingContractId }),
        afterDeploy
    )

	useEffect(() => {
		fetchUsers()
	}, [limit, offset, search])

	useEffect(() => {
        if (deployVestingContractId) {
            deployVestingContract()
        }
    }, [deployVestingContractId])

	const loading = loadingUsers || deploying

	return {
		limit,
		loading,
		data: users,
		setOffset,
		setLimit,
		setSearch,
		setDeployVestingContractId
	}
}

export default useDiamondCircleTable
