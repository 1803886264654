import { configureStore } from '@reduxjs/toolkit'
import authReducer from 'components/adminLogin/authSlice'
import digestFormReducer from 'components/emails/digestForm/digestFormSlice'
import triggeredFormReducer from 'components/emails/triggeredForm/triggeredFormSlice'
import eventReducer from 'store/reduxStates/eventSlice'

export const store = configureStore({
	reducer: {
		auth: authReducer,
		event: eventReducer,
		digestForm: digestFormReducer,
		triggeredForm: triggeredFormReducer,
	},
})
