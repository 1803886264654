import { showMessage } from 'utils/notification.util'
import { getMarketingUsersCsvReport, getReportsService } from 'services/AdminService'
import useAxios from 'hooks/useAxios'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const useReports = () => {
	const [reports, setReports] = useState([])
	const { reportCompleted } = useSelector(state => state.event)

	const [{ loading: downloading }, downloadReport] = useAxios(
		getMarketingUsersCsvReport(),
		(data, error) => {
			if (!error) {
				showMessage({ message: 'Report generation is in progress. This could take a few minutes.' })
			}
		}
	)

	const [{ loading: loadingReports }, getReports] = useAxios(
		getReportsService(),
		(data, error) => {
			if (!error) {
				setReports(data)
			}
		}
	)

	const loading = downloading || loadingReports

	useEffect(() => {
		console.log('reportCompleted', reportCompleted)
		getReports()
	}, [reportCompleted])

	return {
		downloadReport,
		loading,
		reports
	}
}
export default useReports
