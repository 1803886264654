import { Button } from 'react-bootstrap'
import useVestingWalletsForm from './useVestingWalletsForm'
import { LoadMask } from 'components/common/loadMask'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { CustomSelect } from 'components/common/customSelect'
import { formatNumberWithCommas } from 'utils/form.util'

const VestingWalletsForm = () => {
	const {
		types,
		data,
		loading,
		vestingSubwalletTypeId,
		handleCancel,
		handleChange,
		handleChangeType,
		handleSubmit,
		addWallet,
		removeWallet,
		checkUserExistence
	} = useVestingWalletsForm()

	// Calculate total initial vesting amount
	const totalInitialVesting = data.reduce((sum, wallet) => {
		// Only add if both total and initialVesting are numbers
		if (wallet.total && wallet.initialVesting) {
			return sum + (wallet.total * (wallet.initialVesting / 100))
		}
		return sum
	}, 0)

	// Find the selected type to display its details
	const selectedType = types.find(type => type.id === vestingSubwalletTypeId)

	return (
		<>
			{loading ? (
				<LoadMask />
			) : (
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">Create Platform Vesting Wallets</h4>
							</div>
							<div className="card-body">
								<div className="alert alert-info mb-4" role="alert">
									<strong>Total Initial Vesting: {formatNumberWithCommas(totalInitialVesting)} FAN</strong> - That's the needed amount to be in the vesting subwallet
								</div>

								<div className="basic-form">
									<form onSubmit={(e) => e.preventDefault()}>
										{/* Global Vesting Subwallet Type Selection */}
										<div className="form-row mb-4 align-items-center">
											<div className='form-group col-md-6'>
												<label>Vesting Subwallet Type*</label>
												<CustomSelect
													{...{
														value: vestingSubwalletTypeId,
														setValue: handleChangeType,
														options: types,
														placeholder: "Select Vesting Subwallet Type"
													}}
												/>
											</div>
											{selectedType && (
												<div className='form-group col-md-6'>
													<div className="d-flex">
														<div className="mr-3">
															<small className="text-muted">FAN Balance:</small>
															<p>{formatNumberWithCommas(selectedType.fanBalance)} FAN</p>
														</div>
														<div className="mr-3">
															<small className="text-muted">POL Balance:</small>
															<p>{formatNumberWithCommas(selectedType.polBalance)} POL</p>
														</div>
														<div>
															<small className="text-muted">Address:</small>
															<p>{selectedType.address || 'N/A'}</p>
														</div>
													</div>
												</div>
											)}
										</div>

										{data.map((wallet, index) => (
											<div key={index} className="wallet-form-section mb-4">
												<div className="form-row">
													<div className="form-group col-md-4">
														<label>Email*</label>
														<div className="input-group">
															<input
																value={wallet.email || ''}
																type="text"
																className="form-control"
																placeholder="Email"
																onChange={(e) => {
																	handleChange({ email: e.target.value }, index)
																}}
															/>
															<div className="input-group-append">
																<Button 
																	variant="secondary" 
																	onClick={() => checkUserExistence(wallet.email, index)}
																	disabled={!wallet.email}
																>
																	Verify Email
																</Button>
															</div>
														</div>
													</div>
													<div className="form-group col-md-4">
														<label>{`First Name${wallet.userExists ? '' : '*'}`}</label>
														<input
															value={wallet.newUserData?.firstName || ''}
															type="text"
															className="form-control"
															placeholder="First Name"
															disabled={wallet.userExists}
															required={!wallet.userExists}
															onChange={(e) => {
																handleChange({ 
																	newUserData: { 
																		...wallet.newUserData, 
																		firstName: e.target.value 
																	} 
																}, index)
															}}
														/>
													</div>
													<div className="form-group col-md-4">
														<label>{`Last Name${wallet.userExists ? '' : '*'}`}</label>
														<input
															value={wallet.newUserData?.lastName || ''}
															type="text"
															className="form-control"
															placeholder="Last Name"
															disabled={wallet.userExists}
															required={!wallet.userExists}
															onChange={(e) => {
																handleChange({ 
																	newUserData: { 
																		...wallet.newUserData, 
																		lastName: e.target.value 
																	} 
																}, index)
															}}
														/>
													</div>
													<div className="form-group col-md-4">
														<label>Total Amount*</label>
														<input
															value={wallet.total || ''}
															type='number'
															className='form-control'
															placeholder='E.g. 1000 FAN'
															onChange={(e) => handleChange({ total: Number(e.target.value) }, index)}
														/>
													</div>
													<div className="form-group col-md-4">
														<label>Start Date*</label>
														<DatePicker 
															selected={wallet.startDate} 
															onChange={(date) => handleChange({ startDate: date }, index)} 
															className="form-control" 
														/>
													</div>
													<div className="form-group col-md-4">
														<label>End Date*</label>
														<DatePicker 
															selected={wallet.endDate} 
															onChange={(date) => handleChange({ endDate: date }, index)} 
															className="form-control" 
														/>
													</div>
													<div className="form-group col-md-4">
														<label>Lockup Period*</label>
														<input
															value={wallet.lockup === 0 ? 0 : (wallet.lockup || '')}
															type='number'
															className='form-control'
															placeholder='Lockup period in months'
															onChange={(e) => handleChange({ lockup: Number(e.target.value) }, index)}
														/>
													</div>
													<div className="form-group col-md-4">
														<label>Leakout*</label>
														<input
															value={wallet.numIntervals === 0 ? 0 : (wallet.numIntervals || '')}
															type='number'
															className='form-control'
															placeholder='Number of leakout intervals'
															onChange={(e) => handleChange({ numIntervals: Number(e.target.value) }, index)}
														/>
													</div>
													<div className="form-group col-md-4">
														<label>Initial Release (%)*</label>
														<input
															value={wallet.initialRelease === 0 ? 0 : (wallet.initialRelease || '')}
															type='number'
															min={0}
															max={100}
															className='form-control'
															placeholder='Initial release percentage'
															onChange={(e) => handleChange({ initialRelease: Number(e.target.value) }, index)}
														/>
													</div>
													<div className="form-group col-md-4">
														<label>Initial Vesting (%)*</label>
														<input
															value={wallet.initialVesting === 0 ? 0 : (wallet.initialVesting || '')}
															type='number'
															className='form-control'
															placeholder='Initial vesting percentage'
															onChange={(e) => handleChange({ initialVesting: Number(e.target.value) }, index)}
														/>
													</div>
													<div className="form-group col-md-4">
														<label>Vesting Percentage*</label>
														<input
															value={wallet.vestingPercentage === 0 ? 0 : (wallet.vestingPercentage || '')}
															type='number'
															min={0}
															max={100}
															className='form-control'
															placeholder='Vesting percentage per interval'
															onChange={(e) => handleChange({ vestingPercentage: Number(e.target.value) }, index)}
														/>
													</div>
													<div className="form-group col-md-4">
														<label>Release Percentage*</label>
														<input
															value={wallet.releasePercentage === 0 ? 0 : (wallet.releasePercentage || '')}
															type='number'
															min={0}
															max={100}
															className='form-control'
															placeholder='Release percentage per interval'
															onChange={(e) => handleChange({ releasePercentage: Number(e.target.value) }, index)}
														/>
													</div>
												</div>
												{data.length > 1 && (
													<Button 
														variant="danger" 
														onClick={() => removeWallet(index)}
														className="mb-3"
													>
														Remove Wallet
													</Button>
												)}
											</div>
										))}
										<div className="d-flex justify-content-between">
											<div>
												<Button variant="primary" onClick={addWallet} className="mr-2">
													Add Another Wallet
												</Button>
												<Button className="mr-2" variant="success" onClick={handleSubmit}>
													Submit
												</Button>
												<Button variant="danger" onClick={handleCancel}>
													Cancel
												</Button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default VestingWalletsForm
