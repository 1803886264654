export const getVestingWallets = (params) => ({
	method: 'get',
	url: '/admin/vesting/wallets',
    params,
	autoexec: true
})

export const getVestingSubwalletsTypes = () => ({
	method: 'get',
	url: '/admin/vesting/subwallets',
	autoexec: false
})

export const bulkCreateVestingWallets = (data) => ({
    method: 'post',
    url: '/admin/vesting/bulkCreate',
    data,
    autoexec: false
})
