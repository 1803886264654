import { Button } from 'react-bootstrap'
import useReports from './useReports'
import { LoadMask } from 'components/common/loadMask'
import { isNil } from 'lodash'

const Reports = () => {
	const { downloadReport, loading, reports } = useReports()

	const marketingReport = reports.find((report) => report.typeId === 1)

	return (
		<>
			{loading ? (
				<LoadMask />
			) : (
				<>
					<div className="row">
						<div className="col-xl-3 col-sm-6">
							<div className="card">
								<div className="card-body text-center">
									<h4 className="text-black mb-4 font-w500">
										{'Marketing Cohort Report (detailed)\n'}
										{
											!isNil(marketingReport?.downloadUrl) &&
											<a href={marketingReport?.downloadUrl}
												target="_blank"
												rel="noopener noreferrer"
												className="text-primary mt-20">
												Download latest report
											</a>
										}
									</h4>
									<Button
										className="mr-2"
										variant="success"
										onClick={downloadReport}
										disabled={loading}
									>
										Generate New Report
									</Button>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	)
}
export default Reports
